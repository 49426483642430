<template>
  <div ref="cesium" class="h-100 data_wrap">
    <div class="data_tool p-10">
      <Button type="info" size="small" class="tool-button" @click="setNaviga">指北针</Button>
      <Button type="info" size="small" class="tool-button m-t-10" @click="rotate">绕点</Button>
      <Button type="info" size="small" class="tool-button m-t-10" @click="birdView">鸟瞰</Button>
      <Button type="info" size="small" class="tool-button m-t-10" @click="lookDown">俯视</Button>
      <Button type="info" size="small" class="tool-button m-t-10" @click="lookAt">平视</Button>
      <Button type="info" size="small" class="tool-button m-t-10" @click="getPanoramaView">全景</Button>
    </div>
    <transition name="fade">
      <div :class="['map_tool p-10', flag ? '' : 'left_close']">
        <div class="detail_info">
          <div class="title"><span class="line"></span>研学点详情</div>
          <div class="detail_name ellipsis f-20 m-t-10">
            {{ detail.pointName }}
          </div>
          <Vgroup :labelWidth="90" class="transport">
            <Vcell label="位置：" class="bg_color">
              {{ `${province} - ${city} - ${county}` }}
            </Vcell>
            <Vcell
              label="是否公开："
              :value="detail.published ? '公开' : '非公开' || '未知'"
            />
            <Vcell
              label="适合季节："
              :value="detail.pointSeason || '未知'"
              class="bg_color"
            />
            <Vcell label="所属机构：" :value="base_org" />
            <Vcell label="所属学段：" class="bg_color">
              <span v-if="!detail.applicablePhases">未知</span>
              <template v-else>
                <Tag
                  :color="color[index]"
                  v-for="(item, index) in detail.applicablePhases"
                  :key="index"
                  class="m-r-5"
                  >{{ item }}</Tag
                >
              </template>
            </Vcell>
            <Vcell label="所属学科：">
              <span
                v-for="(item, index) in detail.subjects"
                :key="index"
                class="m-r-5"
                >{{ item || "未知" }}</span
              >
            </Vcell>
            <Vcell
              label="采集时间："
              :value="detail.createTime"
              class="bg_color"
            />
            <Vcell label="采集人：" :value="detail.ownerName || '未知'" />
            <Vcell
              label="简介："
              :value="detail.pointInfo || '暂无'"
              class="bg_color"
            />
          </Vgroup>
          <div class="detail_name f-16 m-t-10">研学点图片</div>
          <div class="p-10 p-l-20" v-if="!detail.pintImg">暂无图片</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100"
            v-else
          >
            <Col
              span="12"
              v-for="(item, index) in detail.pointImg"
              :key="index"
              class="w-48 m-t-10"
            >
              <img :src="item" class="point_img" />
            </Col>
          </Row>
          <div class="detail_name f-16 m-t-10">研学点视频</div>
          <div class="p-10 p-l-20" v-if="!detail.pointVideo">暂无视频</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100 p-l-20"
            v-else
          >
            <Col
              span="12"
              v-for="(item, index) in detail.pointVideo"
              :key="index"
              class="w-48 m-t-10"
            >
              <video class="point_video" controls>
                <source :src="item" />
              </video>
            </Col>
          </Row>
          <div class="detail_name f-16 m-t-10">研学点课程</div>
          <div class="p-10 p-l-20" v-if="!detail.pointCourse">暂无课程</div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            :gutter="10"
            class="w-100 p-l-20"
            v-else
          >
            <Col
              span="3"
              v-for="(item, index) in detail.pointCourse"
              :key="index"
              class="w-48 m-t-10"
            >
              <img src="/static/images/model/course_img.png" class="c-h" @click="openEpub(item)" />
            </Col>
          </Row>
        </div>
        <div class="map_left_close">
          <Icon
            size="40"
            class="map_left_close_icon"
            :type="flag ? 'md-arrow-round-back' : 'md-arrow-round-forward'"
            @click="flag = !flag"
          />
        </div>
      </div>
    </transition>
    <!-- <Modal v-model="iShow" title="查看课程" fullscreen footer-hide>
      <iframe :ref="uuid" :src="iframeSrc" width="100%" height="100%" frameborder="0"></iframe>
    </Modal> -->
    <PanoramaView :modal="modal" />
  </div>
</template>

<script>
// import {util} from '@/libs'
import { CesiumMap } from "@/libs";
import DrawTool from "@/libs/mapByCesium/DrawTool.js";
import { getPointById } from "@/libs/api/point";
import { getPanoramaById } from '@/libs/api/panorama'
import PanoramaView from "./panoramaView.vue"
export default {
  components: { PanoramaView },
  data() {
    return {
      // uuid: util.uuid(),
      // iShow: false,
      // iframeSrc: '',
      panorama: {},
      modal: {
        show: false,
        data: {}
      },
      flag: true,
      cesium: null,
      tool: null,
      detail: {},
      province: null,
      city: null,
      county: null,
      base_org: null,
      color: {
        0: "rgb(57, 204, 196)",
        1: "rgb(79, 181, 255)",
        2: "rgb(255, 161, 89)",
        3: "rgb(78, 138, 255)",
        4: "#7232dd",
        5: "rgb(57, 204, 196)",
      },
      color2: {
        0: "magenta",
        1: "volcano",
        2: "orange",
        3: "cyan",
        4: "geekblue",
        5: "purple",
      },
    };
  },
  methods: {
    openEpub(epub_url) {
      console.log(epub_url)
      let epubId = null
      if(epub_url.includes('/minio-netdisk/file/')) {
        epubId = epub_url.split('/')[epub_url.split('/').length - 1]
      }
      // this.$router.push(`/researchCourseView/${epubId}`)
      // window.open(`${process.env.VUE_APP_BASE_API}/researchCourseView/${epubId}`)
      window.open(`${process.env.VUE_APP_BASE_EPUB}?fileUrl=${process.env.VUE_APP_BASE_API}/minio-netdisk/file/e/${epubId}.epub`)

      // this.iShow = true
      // this.iframeSrc = `${process.env.VUE_APP_BASE_EPUB}?fileUrl=${process.env.VUE_APP_BASE_API}/minio-netdisk/file/e/${epubId}.epub`
    },
    // 罗盘
    setNaviga() {
      this.cesium.setNavigation();
    },
    // 绕点
    rotate() {
      if (!this.tool.rotateBool) this.tool.rotateCamera(this.detail);
      else this.tool.stopRotate();
    },
    // 鸟瞰 90度
    birdView() {
      this.tool.birdEyeView(this.detail, 90);
    },
    // 俯视 45度默认值
    lookDown() {
      this.tool.birdEyeView(this.detail);
    },
    // 平视 90度
    lookAt() {
      this.tool.birdEyeView(this.detail, 5);
    },
    // 查看全景
    getPanoramaView() {
        Object.assign(this.modal, { show: true, data: this.panorama })
    },

    // 获取全景详情
    getPanorama(id) {
      getPanoramaById({ id }).then(res => {
        if(res.code == "HA0200") {
          this.panorama = res.data
        }
      })
    },
    getData() {
      getPointById({ id: this.$route.query.id }).then(({ data }) => {
        this.detail = Object.assign({}, this.detail, {
          ...data,
          id: data.researchPointId,
          name: data.pointName,
          lng: data.pointCoord.lon,
          lat: data.pointCoord.lat,
          ele: 500,
          width: 30,
          height: 30,
          image: "leida.svg",
        });
        // console.log('this.detail', this.detail)
        const { lng, lat, ele } = this.detail;
        this.tool.addPoint(this.detail);
        this.cesium.pointCamera(lng, lat, 500);
        this.province = this.detail.pointAdd[1];
        this.city = this.detail.pointAdd[2];
        this.county = this.detail.pointAdd[3];
        this.base_org = this.detail.pointOrg.base;
        if (this.detail.pointImg !== null && this.detail.pointImg.length > 0) {
          this.listData = this.detail.pointImg;
        } else {
          this.listData = null;
        }
        if (
          this.detail.pointVideo !== null &&
          this.detail.pointVideo.length > 0
        ) {
          this.videoData = this.detail.pointVideo;
        } else {
          this.videoData = null;
        }
        if(this.detail.point360) {
          this.getPanorama(this.detail.point360)
        }
      });
    },
  },

  async mounted() {
    // 加载cesium
    const cesium = await new CesiumMap(this.$refs.cesium, {
      homeButton: false,
      sceneModePicker: false,
      isCredit: true,
      isAnimation: true,
      isTimeline: true,
    });
    this.cesium = cesium;
    this.tool = await new DrawTool(cesium);
    this.getData();
  },
  beforeDestroy() {
    this.cesium.removeCesium();
  },
};
</script>

<style lang="less" scoped>
.data_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .data_tool {
    position: absolute;
    top: calc(50% - 100px);
    z-index: 999;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .detail_info {
    height: 100%;
    overflow-y: scroll;
  }
  .map_tool {
    z-index: 999;
    position: absolute;
    bottom: 20px;
    color: #fff;
    left: 20px;
    height: calc(~"100% - 110px");
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    width: 500px;
    transition: all 3s;
    // border: solid 1px rgb(38, 170, 228);
    border-radius: 0 20px 20px 0;
    // overflow: hidden;
  }
  .title {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;

    .line {
      background-color: #18ffff;
      display: inline-block;
      border-left: 3px solid #18ffff;
      border-right: 3px solid #18ffff;
      border-radius: 5px;
      height: 30px;
      width: 6px;
      margin-right: 10px;
    }
  }
  .detail_name {
    padding-left: 20px;
    font-weight: 600;
  }
  .bg_color {
    background: rgba(24, 123, 206, 0.2);
  }
  .point_img {
    width: 100%;
    height: 140px;
  }
  .point_video {
    width: 100%;
    height: 150px;
  }

  .map_left_close {
    position: absolute;
    top: calc(50% - 60px);
    left: 500px;
    width: 60px;
    height: 60px;
    border: solid 1px #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    z-index: 999;
  }
  .map_left_close_icon:hover {
    color: #efefef;
  }
  .left_close {
    left: -500px;
  }
}
</style>
